import React, {useState} from 'react'
import useIconCallBuilder from './useIconCallBuilder'

const useIconCalls = () => {
    const { callContract } = useIconCallBuilder()
    // const [iDogeNfts, setiDogeNfts] = useState<number>(0)

    const getOwnediDogeNfts = async (address) => {
        const res = await callContract(
            "cx883042c10602b50defe69dd8c44acb21e999ada2",
            "getBalance",
            {"_address":address}
        )

        return parseInt(res,16)
    }

    return { 
        getOwnediDogeNfts 
    }
}

export default useIconCalls