import React, { Component } from 'react';
import {
  ContractComponent
} from '../../../../components'

class TokenContractRead extends Component {
  render() {
    return <ContractComponent {...this.props}/>
  }
}

export default TokenContractRead;
