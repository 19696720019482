import React, { Component } from 'react';
import { TransactionListPageContainer } from '../containers'

class TransactionListPage extends Component {
  render() {
    return (
      <TransactionListPageContainer />
    );
  }
}

export default TransactionListPage;
