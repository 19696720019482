import React, { Component } from 'react';
import { AddressBookPageContainer } from '../containers'


class AddressBookPage extends Component {
    render() {
       return(
        <AddressBookPageContainer />  
       );
    }
  }
  
  export default AddressBookPage;