import React, {useState,useEffect, useContext} from 'react';
import LinkCell from './LinkCell'
import {
  isContractAddress
} from '../../../utils/utils'

import AppContext from '../../../AppContext';

const AddressLink = ({to, label, onClick}) => {
  const { addressBookUser, globalAddressBook }        = useContext(AppContext)
  const [ addressToRender, setAddressToRender ] = useState(label)
	// const checkAddressBook = useAddressBook()

  useEffect(() => {
    if (addressBookUser) {
      if (label in addressBookUser) {
        setAddressToRender(addressBookUser[label])
      }
    }
  }, [addressBookUser, globalAddressBook])
  
  return (
    <LinkCell
      pageType={isContractAddress(to) ? 'contract' : 'address'}
      aClassName="on"
      to={to}
      label={addressToRender} // here should be the addressbook label
      onClick={onClick}
    />
  )
}

export default AddressLink;
