import React, { useEffect, useState, useContext } from 'react'
import BodyClassName from 'react-body-classname'
import { setAddress } from '../../redux/actions/storageActions'
import AppContext from '../../AppContext'
import { connect } from 'react-redux';
import Login from './login'
import AddressBook from './addressBook'
import AddAddress from './addAddress'
import Signup from './signup'
import useIconCalls from '../../hooks/useIconCalls';
import useIconCallBuilder from '../../hooks/useIconCallBuilder';
import GlobalAddressBook from './globalAddressBook';
// /src/style-custom/custom.css

function index({walletAddress, amountOfNftsOwned}) {
  const { loggedInUser, setLoggedInUser, pb, setAddressBookUser, setRecordId, isWalletRegistered } = useContext(AppContext)
  const [loggedInEmail, setLoggedInEmail] = useState('guest')
  const [registeredWallet, setRegistreredWallet] = useState(null)
  const [showGobalAddressBook, setShowGlobalAddressBook] = useState(false)

  useEffect(() => {
    if (pb.authStore.isValid) {
      setLoggedInUser(pb.authStore.model)
    }
  }, [])

  useEffect(() => {
    if (loggedInUser) {
      setLoggedInEmail(loggedInUser.email)
      setRegistreredWallet(loggedInUser.wallet)
    }
  }, [loggedInUser])

  const logOut = () => {
    pb.authStore.clear()
    setLoggedInUser(null)
    setLoggedInEmail('guest')
    setAddressBookUser(null)
    setRecordId(null)
  }

  return (
    <div className="content-wrap">
      <div className="screen0">
        <div className={`wrap-holder`}>
        {/* <p className="title token">Address Book</p> */}
        {/* <p className="title token">Welcome {loggedInEmail}</p> */}
        <div>
          {loggedInUser &&  <button onClick={logOut}>logout</button>}
        </div>
        
        {
          loggedInUser ? 
        
          <div style={{display: 'flex', flexDirection: 'column', gap:'8px'}}>
           
            {registeredWallet && <p>Registered Wallet: {registeredWallet}</p>}
            <p>{amountOfNftsOwned > 0 ? `You own ${amountOfNftsOwned} iDoge NFT(s)!` : "You don't own iDoge NFTs"}</p>
            <div
            style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap:'18px'}}
            >
              <p 
              className={!showGobalAddressBook ? "extended-title" : "extended-title-inactive"}
              onClick={()=>setShowGlobalAddressBook(false)}>
                Your Addressbook
              </p>
              <p 
              className={showGobalAddressBook ? "extended-title" : "extended-title-inactive"}
              onClick={()=>setShowGlobalAddressBook(true)}
              >
                Global Addressbook
              </p>
            </div>
            {}
            
            {
            showGobalAddressBook ? 
              <GlobalAddressBook walletAddress={walletAddress}/> : 
              <div>
                <AddressBook walletAddress={walletAddress}/>
                <AddAddress/>
              </div>
            }
            
            {/* <Signup/> */}
          </div>
        : 
          <>
          { isWalletRegistered ?
            <Login/>:
            <Signup/>
          }
            
            {/* <Signup/> */}
          </>
        }

        </div>
         
      </div>
    </div>
  )
}

// export default index

const mapStateToProps = (state) => {
  return {
    walletAddress: state.storage.walletAddress,
    amountOfNftsOwned: state.amountOfNftsOwned.amountOfNftsOwned,
  };
};



export default connect(mapStateToProps)(index);