
import { SET_AMOUNT_OF_NFTS_OWNED } from '../actionTypes/actionTypes';

const initialState = {
  amountOfNftsOwned: 0,
};

const amountOfNftsOwnedReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AMOUNT_OF_NFTS_OWNED:
      return {
        ...state,
        amountOfNftsOwned: action.payload,
      };
    default:
      return state;
  }
};

export default amountOfNftsOwnedReducer;