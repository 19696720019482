import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { setSelectedChain } from '../../redux/actions/actions';
import { SearchBox } from '../../components';
import { Connect } from '../../components';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChain: 'm',
    };
    console.log('Header props', props);
  }
  
  handleChainSelect = (selectedChain) => {
    this.props.setSelectedChain(selectedChain);
    // add to local storage
    localStorage.setItem('selectedChain', selectedChain);
    // force a reload
    window.location.reload();
  };

  
  render() {
      return (
          <div className="header-wrap">
              <div className="wrap-holder">
                  <div className="content" style={{position:'relative'}}>
                      <Link to="/">
                          {/* <span className="logo" /> */}
                            <div 
                            style={{color:"white", position:"absolute", left:"8px", top:"20px"}}
                            >
                                <span style={{color:"red"}}>beta</span> ICON Tracker
                            </div>
                      </Link>
                      <div className="link">
                          <ul>
                              <li>
                                  <span>
                                      Address
                                      <em className="img" />
                                  </span>
                                  <ol className="sub-menu">
                                      <li
                                          onClick={() => {
                                              this.props.history.push('/addresses')
                                          }}
                                      >
                                          <span>Addresses List</span>
                                      </li>
                                      <li
                                          onClick={() => {
                                              this.props.history.push('/contracts')
                                          }}
                                      >
                                          <span>Contracts List</span>
                                      </li>
                                      <li
                                          onClick={() => {
                                              this.props.history.push('/address_book')
                                          }}
                                      >
                                          <span>Address Book</span>
                                      </li>
                                  </ol>
                              </li>
                              <li
                                  onClick={() => {
                                      this.props.history.push('/blocks')
                                  }}
                              >
                                  <span>Block</span>
                              </li>
                              <li
                                  onClick={() => {
                                      this.props.history.push('/transactions')
                                  }}
                              >
                                  <span>Transaction</span>
                              </li>
                              <li>
                                  <span>
                                      Token
                                      <em className="img" />
                                  </span>
                                  <ol className="sub-menu">
                                      <li
                                          onClick={() => {
                                              this.props.history.push('/tokens')
                                          }}
                                      >
                                          <span>Tokens List</span>
                                      </li>
                                      <li
                                          onClick={() => {
                                              this.props.history.push('/tokentransfers')
                                          }}
                                      >
                                          <span>Token Transfers List</span>
                                      </li>
                                  </ol>
                              </li>
                              <li>
                                  <span>
                                      Governance
                                      <em className="img" />
                                  </span>
                                  <ol className="sub-menu">
                                      <li
                                          onClick={() => {
                                              this.props.history.push('/governance')
                                          }}
                                      >
                                          <span>P-Rep List</span>
                                      </li>
                                      <li
                                          onClick={() => {
                                              this.props.history.push('/proposal-list')
                                          }}
                                      >
                                          <span>Network Proposal</span>
                                      </li>
                                  </ol>
                              </li>
                              <li 
                              onClick={()=>this.handleChainSelect('l')}
                              >
                                <span 
                                style={{color: this.props.selectedChain === 'l' ? 'white' : ''}}
                                >
                                  L</span>
                              </li>
                              <li 
                              onClick={()=>this.handleChainSelect('b')}
                              >
                                <span 
                                style={{color: this.props.selectedChain === 'b' ? 'white' : ''}}
                                >B</span>
                              </li>
                              <li 
                              onClick={()=>this.handleChainSelect('m')}
                              >
                                <span 
                     
                                style={{ color: this.props.selectedChain === 'm' ? 'white' : '' }}
                                >M</span>
                              </li>
                          </ul>
                          <div className="link-right">
                              <SearchBox {...this.props} />
                              <Connect {...this.props} />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedChain: state.selectedChain,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedChain: (selectedChain) => dispatch(setSelectedChain(selectedChain)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
