import React, { Component } from 'react';
import { BlockDetailPageContainer } from '../containers'

class BlockDetailPage extends Component {
  render() {
    return (
      <BlockDetailPageContainer />
    );
  }
}

export default BlockDetailPage;
