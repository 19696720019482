// a function that returns utf8 string from hex string
export const unHex = (str) => {    
    // check if string is hex
    const isHex = (str) => {
        const regex = /^[0-9a-fA-F]+$/
        return regex.test(str)
    }
    
    // if (!isHex(str)) {
    //     console.log('not hex', str)
    //     return str
    // }
    
    // if not valid utf8, console.log
    if (!isValidUTF8(str)) {
        console.log('not valid utf8', str)
        return str
    }

    // if not start with 0x, return str
    if (!str.startsWith('0x')) {
        return str
    }

    const newStr = str.slice(2)
    const buf = Buffer.from(newStr, 'hex')
    const utf8 = buf.toString('utf8')
    return utf8
}

export function isValidUTF8(string) {
    try {
      new TextEncoder().encode(string);
      return true;
    } catch (error) {
      return false;
    }
}