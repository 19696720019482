'use client';
import { useEffect, useState } from 'react';
import IconService from 'icon-sdk-js';

// import { useGlobalContext } from '../context/globalContext';

const useIconCallBuilder = () => {
  const [iconService, setIconService] = useState();

  useEffect(() => {
    const HttpProvider = IconService.HttpProvider; 
    const api_endpoints = {
      mainnet: "https://ctz.solidwallet.io/api/v3",
      testnet: "https://lisbon.net.solidwallet.io/api/v3",
    }

    let PROVIDER:any;
    PROVIDER = new HttpProvider(api_endpoints.mainnet);
    const iconService = new IconService(PROVIDER);

    // console.log(iconService)
    setIconService(iconService)
  }, [])

  // build a call object
  const callContract = async(to: string, method: string, params:object) =>  {
    const txObj = new IconService.IconBuilder.CallBuilder()
    .to(to)
    .method(method)
    .params(params)
    .build()

    const res = await iconService.call(txObj).execute()
    return res
  }


  return { callContract, iconService } ;
};

export default useIconCallBuilder;