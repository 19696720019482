import React, { Component } from 'react';
import {
  TxBottom
} from '../../../../components'

class ContractTokenTransfers extends Component {
  render() {
    return <TxBottom {...this.props}/>
  }
}

export default ContractTokenTransfers;

