// pauls stuff
import { SET_SELECTED_CHAIN, SET_AMOUNT_OF_NFTS_OWNED } from '../actionTypes/actionTypes';

export const setSelectedChain = (selectedChain) => {
    return {
      type: SET_SELECTED_CHAIN,
      payload: selectedChain,
    };
};

export const setAmountOfNftsOwned = (amount) => {
    return {
      type: SET_AMOUNT_OF_NFTS_OWNED,
      payload: amount,
    };
};