'use client';
import { useEffect, useState } from 'react';
import IconService from 'icon-sdk-js';


const useIconBlockchain = () => {
    const [signature, setSignature] = useState("")
    
    // CREATE EVENTLISTENER
    async function eventHandler(event: any){
        
        const { type, payload, header } = event.detail;
        // console.log(type, payload)
        switch (type) {
            // case "RESPONSE_HAS_ACCOUNT":
            //     // console.log(type, payload)
            //     break
            // case "RESPONSE_HAS_ADDRESS":
            //     break
            // case "RESPONSE_ADDRESS":
            //     // setAccount(payload)
            //     // sessionStorage.setItem('account', payload)
            //     break
            // case "RESPONSE_JSON-RPC":                
            //     if (payload.error) return 

            //     const qTx: qTransactionChecker = {
            //         txobject: payload.result,
            //     }

            //     setTransactionToCheck(qTx)
          
            //     break 
            // case "CANCEL_JSON-RPC": 
            //     break
            case "RESPONSE_SIGNING":
                // console.log('RESPONSE_SIGNING', JSON.stringify(payload));
                setSignature(payload)
                // console.log("NEW SIGNATURE", payload)
                // console.log(JSON.stringify(payload));
                // console.log("getRaw", payload.getProperties());
                // store.dispatch(signTransaction({ signature: payload }));
                break
            case "CANCEL_SIGNING":
                break

            default:
        }
    }

    const setEventListeners = () => {
        window.removeEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
        window.addEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
    }
  
    return { setEventListeners, signature, eventHandler } ;
  };
  
  export default useIconBlockchain;