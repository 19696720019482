import { SET_SELECTED_CHAIN } from '../actionTypes/actionTypes';

let initialState = 'm'; // Set your initial selected chain here
// check for local storage and set initial state to that if it exists
if (localStorage.getItem('selectedChain')) {
  // check if it is m l or b
  const chain = localStorage.getItem('selectedChain');
  if (chain === 'm' || chain === 'l' || chain === 'b') {
    initialState = chain;
  }
}

const selectedChainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_CHAIN:
      return action.payload;
    default:
      return state;
  }
};

export default selectedChainReducer;