import React, {useState, useContext, useEffect} from 'react'
import AppContext from '../../AppContext'

function AddAddress() {
    const { loggedInUser, setLoggedInUser, pb, setAddressBookUser, addressBookUser, recordId } = useContext(AppContext)
    const [address, setAddress] = useState('')
    const [name, setName] = useState('')
    const [userIsVerified, setUserIsVerified] = useState(false)
    const [mailSent, setMailSent] = useState(false)

    useEffect(() => {
        if (loggedInUser) {
            const verified = loggedInUser['verified']
            setUserIsVerified(verified)
        }
    }, [loggedInUser])


    const handleAddAddress = async () => {
        
        if (address === null || name === null) {
            return
        }
        if (!address || !name) {
            alert('please fill in both fields')
            return
        }

        // if address already exists, return
        if (address in addressBookUser) {
            return
        }

        // if address is not 42 chars, return
        if (address.length !== 42) {
            return
        }

        // if address does not start with hx or cx, return
        if (!address.startsWith('hx') && !address.startsWith('cx')) {
            return
        }

        const data = addressBookUser
        data[address] = name
        
        const userId = loggedInUser.id
 
        const _data = {
            "adr": data,
            "user": userId
        };
        
        const _record = await pb.collection('addresses').update(recordId, _data);

        const newobj = {...addressBookUser, [address]: name}
        setAddressBookUser(newobj)
        setAddress('')
        setName('')
    }

    const handleChangeAddress = (e) => {
        setAddress(e.target.value)
    }

    const handleChangeName = (e) => {
        setName(e.target.value)
    }

    const handleResendVerificationEmail = async () => {
        try {
            await pb.collection('users').requestVerification(loggedInUser['email']);
            alert('Verification email sent!')
            setMailSent(true)
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div>
            <div className='address_input_wrapper'>
                <input placeholder='address' onChange={handleChangeAddress} disabled={!userIsVerified} value={address}></input>
                <input placeholder='name' onChange={handleChangeName} disabled={!userIsVerified} value={name}></input>
                <button onClick={handleAddAddress} disabled={!userIsVerified}>add new</button>
            </div>

            { !userIsVerified && 
                <div>
                { !mailSent ?
                    <div style={{display:'flex', flexDirection:'column', gap:'4px', marginTop:'18px'}}>
                        <p>Please verify your email address</p>
                        <button onClick={handleResendVerificationEmail} style={{alignSelf:'flex-start'}}>resend verification email</button>
                    </div>
                    :
                    <div style={{display:'flex', flexDirection:'column', gap:'4px', marginTop:'18px'}}>
                        <p>Check your mail verify your email address, then log out and log back in.</p>
                    </div>
                }
                </div>
            }
        </div>
    )
}

export default AddAddress