
import React, { Component } from 'react';
import {
  TxBottom
} from '../../../../components'
class AddressBonded extends Component {
  

    render() {
    return <TxBottom 
      {...this.props}
      />
  }
}

export default AddressBonded;

