import React, { Component } from 'react';
import { MainPageContainer } from '../containers'

class MainPage extends Component {
  render() {
    return (
      <MainPageContainer />
    );
  }
}

export default MainPage;
