import {useContext, useEffect, useState} from 'react'
import AppContext from '../AppContext'
import { connect } from 'react-redux';
import { store } from '../redux/store/store';


const MINIMUM_OWNED_NFTS = 0;

function useAddressBook() {
    const { loggedInUser, addressBookUser, pb, globalAddressBook } = useContext(AppContext)
    const ownsNfts = store.getState().amountOfNftsOwned.amountOfNftsOwned; // Access the `ownsNfts` state from the Redux store
      

      const checkAddressBook = (address) => {
        if (address === undefined) {
          return address;
        }
        // console.log(ownsNfts, 'ownsNfts')
        let returnAddress = address;
    
        // Access the required state from the Redux store
        const state = store.getState();
    
        // first check if the address is in the global address book
        if (globalAddressBook) {
          if (address in globalAddressBook) {
            returnAddress = globalAddressBook[address];
            return returnAddress;
          }
        }
    
        // then check if the address is in the user's address book
        if (!addressBookUser) {
          return returnAddress;
        }
    
        // console if amount of ntfts owneder is greater than Minimum owned nfts
        // console.log('owned nfts', ownsNfts, 'minimum owned nfts', MINIMUM_OWNED_NFTS)
        // console.log('own more than minimum:', ownsNfts > MINIMUM_OWNED_NFTS)

        if (address in addressBookUser) {
          if (address === loggedInUser.wallet) {
            returnAddress = addressBookUser[address];
            return returnAddress;
          } 
          if (ownsNfts >= MINIMUM_OWNED_NFTS) {
            returnAddress = addressBookUser[address];
            return returnAddress;
          } else {
            returnAddress = address;
            return returnAddress;
          }
        }
    
        return returnAddress;
      };


    return {checkAddressBook}

}

export default useAddressBook;