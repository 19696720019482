import React, { Component } from 'react';
import { TokenDetailPageContainer } from '../containers'

class TokenDetailPage extends Component {
  render() {
    return (
      <TokenDetailPageContainer />
    );
  }
}

export default TokenDetailPage;
