import React, { useState, useEffect } from 'react'
import { Provider } from 'react-redux'
import { store, history } from './redux/store/store'
import Routes from './Routes.js'
import { ConnectedRouter } from 'react-router-redux'
import AppContext from './AppContext';
import PocketBase from 'pocketbase'
import useIconBlockchain from './utils/useIconBlockchain'
import { setAmountOfNftsOwned } from './redux/actions/actions'
import useIconCallBuilder from './hooks/useIconCallBuilder'
import useIconCalls from './hooks/useIconCalls'

const pb = new PocketBase('https://db.store-me.xyz/eoi');

function App() {
    const [loggedInUser, setLoggedInUser] = useState(null)
    const [addressBookUser, setAddressBookUser] = useState(null)
    const [recordId, setRecordId] = useState(null)
    const [selectedChain, setSelectedChain] = useState('m')
    const {setEventListeners} = useIconBlockchain()
    const [activeWallet, setActiveWallet] = useState(null)
    const [isWalletRegistered, setIsWalletRegistered] = useState(false)
    const [globalAddressBook, setGlobalAddressBook] = useState(null)
    const {getOwnediDogeNfts} = useIconCalls()
    const {iconService} = useIconCallBuilder()
    
    useEffect(() => {
        setEventListeners()
    }, [])

    useEffect(() => {
        if (pb.authStore.isValid) {
        setLoggedInUser(pb.authStore.model)
        }
    }, [])

  useEffect(() => {
    const getAddresses = async () => {
        const userId = loggedInUser.id
        // console.log(userId)
        const record = await pb.collection('addresses').getFullList(1 /* batch size */, {
            sort: '-created',
            filter: `user="${userId}"`,
        })
        try {
            const addressBook = record[0]["adr"]
            // address book is key value pairs, sort by value alphabetically
            const sorted = Object.fromEntries(
                Object.entries(addressBook).sort(([,a],[,b]) => a.localeCompare(b))
            );
            setAddressBookUser(sorted)

            // setAddressBookUser(record[0]["adr"])
            setRecordId(record[0]["id"])
        } catch (error) {
            console.log(error)
            console.log(record)
        }
    }
    if (loggedInUser) {
        if (!loggedInUser.id) {
            return
        }
        
        getAddresses()
    }
    }, [loggedInUser])

    useEffect(() => {
        const checkWallet = async () => {
            try {
                const filter = `username="${activeWallet}"`
                const record = await pb.collection('users').getFirstListItem(filter);
                if (record) { setIsWalletRegistered(true) } 
            } catch (e) {}
        }

        if (activeWallet) {
            checkWallet()
        }
    }, [activeWallet])

    useEffect(() => {
        const getGlobalAddressBook = async () => {
            const url = 'https://raw.githubusercontent.com/paulrouge/addressbook/main/addresses.json'
            const response = await fetch(url)
            const data = await response.json()
            setGlobalAddressBook(data)
        }
        getGlobalAddressBook()
    }, [])
    
    
    useEffect(() => {
        if(!loggedInUser) {
            return
        }
        
        if (loggedInUser !== "" && iconService && loggedInUser.wallet) {
            getOwnediDogeNfts(loggedInUser.wallet)
            .then((res) => {
            // console.log(res)
            if (res > 0) {
                store.dispatch(setAmountOfNftsOwned(res))
            }
            })
        }
    }, [loggedInUser, iconService ])
        

    const contextValues = {
        loggedInUser,
        setLoggedInUser,
        addressBookUser,
        setAddressBookUser,
        recordId,
        setRecordId,
        pb,
        selectedChain,
        setSelectedChain,
        activeWallet,
        setActiveWallet,
        isWalletRegistered,
        setIsWalletRegistered,
        globalAddressBook,
    }
    
        return (
            <AppContext.Provider value={contextValues}>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <Routes />
                    </ConnectedRouter>
                </Provider>
            </AppContext.Provider>
        )
    
}

export default App
