import React, {
    useState, 
    useEffect, 
    useContext
} from 'react'
import AppContext from '../../AppContext'

function GlobalAddressBook({walletAddress}) {
    const { globalAddressBook } = useContext(AppContext)

    return (
    <div style={{width:"70%", border:'0px solid black', display:'flex', flexDirection:'column', gap:'17px'}}>
        { 
            globalAddressBook && Object.entries(globalAddressBook).map(([key, value]) => {

                return (
                    <div 
                    key={key}
                    style={{
                        display: 'flex', 
                        flexDirection: 'row', 
                        gap:'15px',
                        justifyContent: 'start',
                        alignItems: 'center',
                    }}
                    >
                        <div 
                        style={{
                            display: 'flex', 
                            gap:'4px', 
                            justifyContent:'space-between',
                            alignItems: 'center',
                            
                        }}>
                            <div style={{fontWeight:'bold', width:'420px'}}>
                                <a 
                                href={key.startsWith('h') ? `/address/${key}` : `/contract/${key}`} 
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    const newTab = window.open(
                                      key.startsWith('h') ? `/address/${key}` : `/contract/${key}`
                                    );
                                    setTimeout(() => {
                                      newTab.document.title = value;
                                    }, 500); // Adjust the timeout value if needed
                                  }}
                                >
                                    {key}
                                </a>
                            </div>
                            <div>{value}</div>
                        </div>
                  
                    </div>
                )
            }
            )

        }

    </div>
  )
}

export default GlobalAddressBook