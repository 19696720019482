import React, { Component } from 'react';
import { AddressListPageContainer } from '../containers'

class AddressListPage extends Component {
  render() {
    return (
      <AddressListPageContainer />
    );
  }
}

export default AddressListPage;
