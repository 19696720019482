export function ExportDataToCSV(data:{}, page) {
    let address = data[0].from_address; // this is not correct!
    const ignore = ['transaction_type', 'status', 'value', 'type','data', 'method'];


    // get keys
    const keys = Object.keys(data[0]).filter(key => !ignore.includes(key));
    // get values, and prepare the values for CSV
    const values = data.map(obj => keys.map((key) => {
        let value = obj[key];
        if (key === "transaction_fee"){
            // value from hex to decimal
            value = parseInt(value, 16) / 10 ** 18;
        }
        if (key === "block_timestamp"){
            // timestamp to date
            value = new Date(value).toLocaleString();
            value = value.replaceAll(',', ' ');
        }
        return value;
    }));

    // add header column
    values.unshift(keys);
    // generate csv string
    let csvString = '';
    values.forEach((row, index) => {
        const rowString = row.join(',');
        csvString += rowString + '\r\n';
    }
    );
    
    // download file
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const file_name = address + '_' + page + '.csv'
    
    link.setAttribute('href', url);
    link.setAttribute('download', file_name);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

}