import React, { useState, useEffect } from 'react';
import { IconAmount, IconConverter } from 'icon-sdk-js'
import { 	
    makeDownloadLink,
	convertNumberToText,
	numberWithCommas,
	isValidData,
	beautifyJson,
	removeQuotes,
	isHex,
	isImageData,
	epochToFromNow,
	convertHexToValue, 
} from '../../../utils/utils';
import useAddressBook from '../../../hooks/useAddressBook';
import DataComponent from './DataComponent';
  

const DataCell = (props) => {
    const {checkAddressBook} = useAddressBook();
    const [loading, setLoading] = useState(false);
    const [viewHex, setViewHex] = useState(props.scam ? true : false);
    const [converted, setConverted] = useState('');
    const [toHex, setToHex] = useState('');
    const [toUtf8, setToUtf8] = useState('');
    const [imgError, setImgError] = useState(false);
    const [workers, setWorkers] = useState([]);

    useEffect(() => {
        getDataString();
        return () => {
        workers.forEach((worker) => {
            worker.terminate();
        });
        };
    }, []);

    const promiseWorker = (type, payload) => {
        let worker = new Worker();
        setWorkers((prevWorkers) => [...prevWorkers, worker]);
        setLoading(true);
        worker.postMessage({ type, payload });
        return new Promise((resolve, reject) => {
        worker.onmessage = (message) => {
            setLoading(false);
            const { payload } = message.data;
            resolve(payload);
            worker.terminate();
        };
        });
    };

    const getDataString = () => {
        const { dataType, dataString } = props;
        const removed = removeQuotes(dataString);
        const _isHex = isHex(removed);
        const newToHex = _isHex ? removed : IconConverter.toHex(removed);
        const newToUtf8 = _isHex ? IconConverter.toUtf8(removed) : removed;
        
        try {
        if (dataType === 'message') {
            if (viewHex && !_isHex) {
                setConverted(newToHex);
                setToHex(newToHex);
            } else if (!viewHex && _isHex) {
                setConverted(newToUtf8);
                setToUtf8(newToUtf8);
            } else {
                setConverted(removed);
            }
        } else {
            setConverted(beautifyJson(dataString, '\t'));
        }
        } catch (error) {
            console.error(error);
            setViewHex(_isHex);
            setConverted(removed);
        }
    };

    const handleClick = () => {
        const { dataType } = props;
        if (dataType === 'message') {
        setViewHex((prevViewHex) => !prevViewHex);
        getDataString();
        }
    };

    const handleImageClick = () => {
        const { dataString } = props;
        const removed = removeQuotes(dataString);
        const data = isHex(removed) ? IconConverter.toUtf8(removed) : removed;
        props.imageConverterPopup({ data });
    };

    const onError = () => {
        setImgError(true);
    };

    const openImage = () => {
        // const img = new Image()
        // img.src = toUtf8
        // const newTab = window.open('', '_blank')
        // newTab.document.write(img.outerHTML)
    };

    useEffect(() => {
        // check the converted string and see if there is a hx or cx prefix in there
        // if there is, then we need to run that value through addressBook
        const check = (str) => {
            const _str = str.toLowerCase();
            // get all the 42 char strings that start with hx or cx
            const matches = _str.match(/(hx|cx)[0-9a-f]{40}/g);
            if (matches) {
                // console.log('matches', matches);
                matches.forEach((match) => {
                    const address = match;
                    const addressBookName = checkAddressBook(address);
                    if (addressBookName) {
                        const re = new RegExp(address, 'g');
                        setConverted((prevConverted) => prevConverted.replace(re, addressBookName));
                    }
                }
                );
            }

        };

        check(converted);
    }, [converted]);

    


    const { dataType, scam } = props;
    const isMessage = dataType === 'message';
    const isButton = isMessage && !loading;
    const _isImageData = isMessage && isImageData(toUtf8) && !imgError;
    const buttonTitle = viewHex ? `Convert to ${_isImageData ? 'Image' : 'UTF-8'}` : 'Convert to HEX';

    return (
        <td className="convert">
        <div className="scroll">
            {!viewHex && _isImageData ? (
            <img src={converted} onClick={openImage} onError={onError} alt="error" />
            ) : (
            <div>
                <p style={{fontSize:"10px", color:"gray"}}>* known addresses are converted to their known names.</p>
                <p>{converted}</p>
                <DataComponent data={converted} />
            </div>
            )}
        </div>
        {isButton && (
            <button className="btn-type-normal" onClick={handleClick} disabled={scam}>
            {buttonTitle}
            </button>
        )}
        </td>
    );
};

export default DataCell;
