import React, {useState,useEffect} from 'react'
import { unHex } from './unHex'

function DataComponent(props) {
    
    const [data, setData] = useState(props)
    const [jsonObject, setJsonObject] = useState(null)

    function tryParseJson(str) {
      console.log(str)
      try {
        return JSON.parse(str)
      } catch (e) {
        console.log(e)
        return null
      }
    }
    
    useEffect(() => {
      console.log(props.data)
      const parsed = tryParseJson(props.data)
      setJsonObject(parsed)
    }, [data])

    useEffect(() => {
      setData(data)
    }, [props.data])

    const renderKeyValuePair = (obj, level = 0) => {
        const indent = '\t'.repeat(level); // Indentation based on level
      
        return Object.entries(obj).map(([key, value]) => {
          if (typeof value === 'object' && value !== null) {
            return (
              <div key={key}>
                <span style={{ marginLeft: `${level * 20}px` }}>
                  {indent}{key}:
                </span>
                <div style={{ marginLeft: `${(level + 1) * 20}px` }}>
                  {renderKeyValuePair(value, level + 1)} 
                </div>
              </div>
            );
          } else {
            return (
              <div key={key} style={{ marginLeft: `${level * 20}px` }}>
                <strong>{indent}{key}:</strong> {unHex(value)}
              </div>
            );
          }
        });
      };
      


    return (
        <div
        // className='convert'
        style={{maxWidth:"760px", overflow:"scroll", padding:"4px", border:"1px solid gray"}}
        >
            {jsonObject && renderKeyValuePair(jsonObject)}
        </div>
    )
}

export default DataComponent