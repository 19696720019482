
import MainPage from './MainPage/MainPage'
import InfoSummary from './MainPage/InfoSummary'
import InfoChart from './MainPage/InfoChart'
import RecentBlocks from './MainPage/RecentBlocks'
import RecentTransactions from './MainPage/RecentTransactions'

import AddressListPage from './AddressesPage/AddressListPage'
import AddressDetailPage from './AddressesPage/AddressDetailPage'

import BlockListPage from './BlocksPage/BlockListPage'
import BlockDetailPage from './BlocksPage/BlockDetailPage'

import ContractListPage from './ContractsPage/ContractListPage'
import ContractExplorerPage from './ContractsPage/ContractExplorerPage'
import ContractsPageSelector from './ContractsPage/ContractsPageSelector'
import ContractDetailPage from './ContractsPage/ContractDetailPage'
import ContractComponent from './ContractsPage/ContractDetailPage/ContractTabs/ContractComponent'

import TransactionListPage from './TransactionsPage/TransactionListPage'
import TransactionDetailPage from './TransactionsPage/TransactionDetailPage'

import TokenListPage from './TokensPage/TokenListPage'
import TokenDetailPage from './TokensPage/TokenDetailPage'

import Header from './Header/Header'
import Footer from './Footer/Footer'
import SearchBox from './Header/SearchBox'
import Popup from './Popup'

import AddressLink from './CommonComponent/Link/AddressLink'
import BlockLink from './CommonComponent/Link/BlockLink'
import TransactionLink from './CommonComponent/Link/TransactionLink'
import TokenLink from './CommonComponent/Link/TokenLink'

import LoadingComponent from './CommonComponent/LoadingComponent'
import Pagination from './CommonComponent/Pagination'
import SortHolder from './CommonComponent/SortHolder'
import StatusHolder from './CommonComponent/StatusHolder'
import TokenStandardHolder from "./CommonComponent/TokenStandardHolder";
import CopyButton from './CommonComponent/CopyButton'
import NoBox from './CommonComponent/NoBox'
import SearchInput from './CommonComponent/SearchInput'
import QrCodeButton from './CommonComponent/QrCode/QrCodeButton'
import QrCodeComponent from './CommonComponent/QrCode/QrCodeComponent'
import AddressCell from './CommonComponent/AddressCell'
import AddressSet from './CommonComponent/AddressSet'
import AmountCell from './CommonComponent/AmountCell'
// import LinkButton from "./CommonComponent/LinkButton"

import TxBottom from './CommonComponent/TxBottom'
import TabTable from './CommonComponent/TabTable'

import TxTableHead from './CommonPage/TxPage/TxTableHead'
import TxTableBody from './CommonPage/TxPage/TxTableBody'

import TxPage from './CommonPage/TxPage'
import SearchPage from './CommonPage/SearchPage'
import DetailPage from './CommonPage/DetailPage'
import NotFoundPage from './CommonPage/NotFoundPage'
import PendingPage from "./CommonPage/PendingPage"

import Banner from './Banner/Banner'
import Connect from './Header/Connect';

import ReportButton from './CommonComponent/ReportButton'

import GovernancePage from './GovernancePage'
import ProposalListPage from './ProposalListPage'
import ProposalDetailPage from './ProposalDetailPage'

import AddressBookPage from './AddressBookPage'

export {
  MainPage,
  InfoSummary,
  InfoChart,
  RecentBlocks,
  RecentTransactions,

  AddressListPage,
  AddressDetailPage,

  BlockListPage,
  BlockDetailPage,

  ContractListPage,
  ContractDetailPage,
  ContractExplorerPage,
  ContractsPageSelector,
  ContractComponent,

  TransactionListPage,
  TransactionDetailPage,

  TokenListPage,
  TokenDetailPage,

  Header,
  Footer,
  SearchBox,
  Popup,

  AddressLink,
  BlockLink,
  TransactionLink,
  TokenLink,

  LoadingComponent,
  Pagination,
  SortHolder,
  StatusHolder,
  TokenStandardHolder,
  CopyButton,
  NoBox,
  SearchInput,
  QrCodeButton,
  QrCodeComponent,
  AddressCell,
  AddressSet,
  AmountCell,
  // LinkButton,

  TxBottom,
  TxTableHead,
  TxTableBody,
  TabTable,

  TxPage,
  SearchPage,
  DetailPage,
  NotFoundPage,
  PendingPage,

  Banner,
  Connect,
  
  ReportButton,

  GovernancePage,
  ProposalListPage,
  ProposalDetailPage,

  AddressBookPage
}
