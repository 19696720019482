// import React from 'react';
import { connect } from 'react-redux';
import { AddressBookPage } from '../../components';
import { withRouter } from 'react-router-dom';

function mapStateToProps(state) {
  return {
    url: state.router.location,
    ...state.addresses
  };
}

const AddressBookPageContainer = withRouter(connect(mapStateToProps)(AddressBookPage));

export default AddressBookPageContainer
