import React, { Component } from 'react';
import { ContractsPageSelectorContainer } from '../containers'

class ContractsPageSelector extends Component {
  render() {
    return (
      <ContractsPageSelectorContainer />
    );
  }
}

export default ContractsPageSelector;
