import React, {
    useState, 
    useEffect, 
    useContext
} from 'react'
import AppContext from '../../AppContext'

function AddressBook({walletAddress}) {
    const { loggedInUser, setLoggedInUser, pb, setAddressBookUser, addressBookUser, setRecordId, recordId } = useContext(AppContext)

    const handleRemoveAddress = async (e:any) => {
        
        // get sibling of e.target
        const sibling = e.target.nextSibling        
        const siblings = sibling.childNodes
        const sibling2 = siblings[0]

        const _key = sibling2.textContent
        const data = addressBookUser

        delete data[_key]
        
        const userId = loggedInUser.id
    
        const _data = {
            "adr": data,
            "user": userId
        };
        
        const _record = await pb.collection('addresses').update(recordId, _data);
        const {key, ...newobj} = addressBookUser
        setAddressBookUser(newobj)
    }



    return (
    <div style={{width:"70%", display:'flex', flexDirection:'column', gap:'8px'}}>
        { 
            addressBookUser && Object.entries(addressBookUser).map(([key, value]) => {

                return (
                    <div 
                    key={key}
                    style={{
                        display: 'flex', 
                        flexDirection: 'row', 
                        gap:'15px',
                        justifyContent: 'start',
                        alignItems: 'center',
                    }}
                    >
                        <div
                        style={{
                            border: '1px solid black', 
                            padding: '4px', 
                            borderRadius: '2px'
                        }}    
                        className='x-button'
                        onClick={key === loggedInUser.wallet ? ()=>{} : (e)=>handleRemoveAddress(e)}
                        >
                        X
                        </div>
                        <div 
                        style={{
                            display: 'flex', 
                            gap:'4px', 
                            justifyContent:'space-between',
                            alignItems: 'center',
                            
                        }}>
                            <div style={{fontWeight:'bold', width:'420px'}}>
                                <a 
                                href={key.startsWith('h') ? `/address/${key}` : `/contract/${key}`} 
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    const newTab = window.open(
                                      key.startsWith('h') ? `/address/${key}` : `/contract/${key}`
                                    );
                                    setTimeout(() => {
                                      newTab.document.title = value;
                                    }, 500); // Adjust the timeout value if needed
                                  }}
                                >
                                    {key}
                                </a>
                            </div>
                            <div>{value}</div>
                        </div>
                  
                    </div>
                )
            }
            )

        }

    </div>
  )
}

export default AddressBook