import React, {  useContext, useEffect, useState } from 'react';
import {
	AddressLink,
	CopyButton
} from '../../components'
import {
	SERVER_TX_TYPE
} from '../../utils/const'
import {
	isScoreTx,
	isContractAddress,
	// isValidData
} from '../../utils/utils'
import useAddressBook from '../../hooks/useAddressBook';
import AppContext from '../../AppContext';

function getBooleans(props) {
	const _isScoreTx = isScoreTx(props.targetAddr, props.txType, props.isFrom)
	const _isContractAddress = isContractAddress(props.targetAddr)
	const _isOtherAddress = props.targetAddr !== props.address
	const _isOtherContract = props.targetContractAddr !== props.address
	const _isListCell = props.tdClassName !== 'trans'
	return {
		_isScoreTx,
		_isContractAddress,
		_isOtherAddress,
		_isOtherContract,
		_isListCell,
	}
}

function addIconClassName(tdClassName, isScoreTx, isContractAddress) {
	const _tdClassName = !!tdClassName ? tdClassName : 'icon'
	
	let className = ""
	if (isScoreTx) {
		className += `${_tdClassName} calen`
	}
	else if (isContractAddress) {
		className += `${_tdClassName}`
	}
	return className
}

function addLinkClassName(isTargetContractAddr, isScoreTx, isOtherContract, isOtherAddress) {
	let className = ""
	if (isScoreTx) {
		if (isTargetContractAddr && isOtherContract) {
			className += " on"
		}
	}
	else {
		if (isOtherAddress) {
			className += " on"
		}
	}
	return className
}

// function getClassName(props, booleans) {
// 	const { _isScoreTx, _isContractAddress, _isOtherAddress, _isOtherContract, _isListCell } = booleans
	
// 	let className = addIconClassName(props.tdClassName, _isScoreTx, _isContractAddress)
// 	if (_isListCell) {
// 		className += addLinkClassName(!!props.targetContractAddr, _isScoreTx, _isOtherContract, _isOtherAddress)
// 	}
// 	return className
// }



// converted to a functional component 
const AddressCell = (props) => {
	// const [addressToRender, setAddressToRender] = useState(props.address)
	const checkAddressBook = useAddressBook()

	function getInnerElements(props, booleans) {
		const { _isScoreTx, _isContractAddress, _isOtherAddress, _isOtherContract, _isListCell } = booleans
	
		let elements = []
		if (_isScoreTx) {
			elements.push(<i key="i" className="img"></i>)
		}
		else if (_isContractAddress) {
			elements.push(<i key="i" className="img"></i>)
		}
	
		if (_isScoreTx) {
			const scoreTxTypeText = SERVER_TX_TYPE[props.txType]
			elements.push(
				<span key="span">
					{(!!props.targetContractAddr && _isOtherContract) ? <AddressLink label={scoreTxTypeText} to={props.targetContractAddr} /> : scoreTxTypeText}
				</span>
			)	
		}
		else {
			elements.push(
				<span key="span" className={props.spanNoEllipsis ? '' : 'ellipsis'}>
					{/* the "selected address" */}
					{_isOtherAddress ? <AddressLink to={props.targetAddr} /> : checkAddressBook(props.address)}
				</span>			
			)
		}
	
		if (!_isListCell) {
			elements.push(
				<CopyButton key="copy" data={props.targetAddr} title={'Copy Address'} isSpan disabled={_isScoreTx} download={props.download} />
			)
		}
	
		return elements
	}

	const Content = () => {
		const { addressBookUser, globalAddressBook, ownsNfts } = useContext(AppContext)
		const [addressToRender, setAddressToRender] = useState(props.address)
		const {checkAddressBook} = useAddressBook()

		
		useEffect(() => {
			if (addressBookUser) {
				// console.log('joe jeojoejoejoejoe', props.address)
				if (props.address in addressBookUser) {
					const _checkAddressBook = checkAddressBook(props.address)	
					// console.log('joe jeojoejoejoejoe', _checkAddressBook)
					setAddressToRender(_checkAddressBook)
				} else {
					setAddressToRender(props.address)
				}
			}
		}, [addressBookUser, props.address, globalAddressBook, ownsNfts])




		const booleans = getBooleans(props)
		// const className = getClassName(props, booleans)
		// const innerElements = getInnerElements(props, booleans)
		return (
			<td className={props.className}>
				{/* {innerElements} */}
				{/* {props.InternalDiv} */}

				{/* {booleans._isScoreTx ? <i key="i" className="img"></i> : null}
				{booleans._isContractAddress ? <i key="i" className="img"></i> : null} */}
				{booleans._isScoreTx ?
					<span key="span">
					{(!!props.targetContractAddr && props._isOtherContract) ? <AddressLink label={SERVER_TX_TYPE[props.txType]} to={props.targetContractAddr} /> : SERVER_TX_TYPE[props.txType]}
					
					</span> 
					: 
					<span key="span" className={props.spanNoEllipsis ? '' : 'ellipsis'}
					
					>
						{booleans._isOtherAddress ? <AddressLink to={props.targetAddr} /> : checkAddressBook(addressToRender)}
					</span>	
				}
				
				{/* {!isValidData(props.targetAddr) && <p className='no'>-</p>} */}
				
			</td>		
		)
	}

	return Content()
	
}

export default AddressCell