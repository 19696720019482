import React, {useState, useContext, useEffect} from 'react'
import AppContext from '../../AppContext'
import { requestSigning } from '../../utils/connect'
import { connect } from 'react-redux'
import { shortenString } from '../../utils/utils'
import useIconBlockchain from '../../utils/useIconBlockchain'
import { verify } from 'crypto'

const secp256k1 = require('secp256k1');
const { sha3_256 } = require('js-sha3');
const ethUtil = require('ethereumjs-util');

const youtube_url = "https://www.youtube.com/watch?v=GSIDS_lvRv4"

function Signup({ walletAddress }) {
  const { signature, eventHandler, setEventListeners } = useIconBlockchain();
  const { loggedInUser, setLoggedInUser, pb, activeWallet, setActiveWallet, isWalletRegistered, setIsWalletRegistered } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState(" ");
  const [signatureVerified, setSignatureVerified] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [payload, setPayload] = useState("");
  const [_signature, setSignature] = useState(signature);
  const [signedUp, setSignedUp] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setActiveWallet(walletAddress)
  }, [walletAddress])


  useEffect(() => {
    setEventListeners(); // Set up event listeners only once when the component mounts

    // Clean up event listeners when the component unmounts
    return () => {
      window.removeEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    setSignature(signature);
  }, [signature]);

  // set is enabled for the sign up button
  useEffect(() => {
    if (!signatureVerified) {
      return setIsEnabled(false)
    }

    // if email contains an @ and a .
    if (email.includes('@') && email.includes('.')) {
      // check if passwords match
      if (password === password2 && password.length > 8) {
        setIsEnabled(true)
      } else {
        setIsEnabled(false)
      }
    } else {
      setIsEnabled(false)
    }
  }, [signatureVerified, email, password, password2])

  const handleChangeEmail = (e) => {
      setEmail(e.target.value)
  }

  const handleChangePassword = (e) => {
    setPassword(e.target.value)
  }

  const handleChangePassword2 = (e) => {
      setPassword2(e.target.value)
  }

  const handleClick = async () => {
    setError("")
    // example create data
    const data = {
      "username": walletAddress,
      "email": email,
      "emailVisibility": true,
      "password": password,
      "passwordConfirm": password2,
      "name": walletAddress,
      "wallet": walletAddress,
      "sig": _signature,
      "msg": payload,
    };

    try {
      const record = await pb.collection('users').create(data);
      
      // get the user id to also create an address book
      const userId = record['id']
      const _data = {
        "adr": {walletAddress : "my wallet"},
        "user": userId
      };
      const _record = await pb.collection('addresses').create(_data);

      // send an email verification request
      await pb.collection('users').requestVerification(email);
      setSignedUp(true)
      setIsWalletRegistered(true)
    } catch (error) {
      console.log(error['message'])
      setError("Something went wrong, please try again")
    }
    
  }
  
  function signTransactionFromICONEX() {
    function getRanHex(size) {
        return [...Array(size)]
          .map(() => Math.floor(Math.random() * 16).toString(16))
          .join('');
    }
    
    const payload = getRanHex(51) + new Date().getTime();
    setPayload(payload)
    // console.log(payload)
    window.parent.dispatchEvent(
      new CustomEvent('ICONEX_RELAY_REQUEST', {
        detail: {
          type: 'REQUEST_SIGNING',
          payload: {
            from: walletAddress,
            hash: payload,
          },
        },
      }),
    );
  }

  // function _validateSignature(signature, address, payload) {
  function _validateSignature() {    
    const address = walletAddress
    try {
      const signatureArray = Buffer.from(_signature, 'base64');
      const signatureBuffer = signatureArray.subarray(0, 64);
      const recoveryBuffer = signatureArray.subarray(64);

      //Genrate the public key from signature, recovery_key and payload
      const publicKey = secp256k1.ecdsaRecover(signatureBuffer,
        parseInt(recoveryBuffer.toString('hex')),
        new Uint8Array(Buffer.from(payload, 'hex')),
        false);
      const publicKeyBuffer = ethUtil.toBuffer(publicKey.slice(1));

      // console.log('Length of public key buffer:' + publicKeyBuffer.length);
    
      //Decode the address from public key hash by taking last 40 bytes
      //Adding hx as prefix for idenitifying the EOA in ICON
      const decodedAddress = 'hx' + sha3_256(publicKeyBuffer).slice(-40);
    
      // console.log('Requestor address ' + decodedAddress);
    
      return address === decodedAddress;
    } catch (e) {
      console.log(e)
      return false;
    }
  }
    
  useEffect(() => {
    if (walletAddress !== "" && signature !== "" && payload !== "") {
      setSignature(signature)
      setSignatureVerified(_validateSignature())
    }

  }, [_signature,payload])

  // useEffect(() => {
  //   console.log('rendered')
  // }, [])

  return (
  <div style={{marginTop:"20px", display:"flex", flexDirection:'column', gap:"8px"}}>  
              <div>
            <p className='title'>SIGN UP</p>
            {walletAddress === "" && <p>Or connect your wallet.</p>}
            <div style={{paddingTop:"20px", paddingBottom:"20px",display:"flex", flexDirection:"column",gap:"4px"}}>
                <p>You do not have an account set up for the wallet you are connected with.</p>
            </div>
        </div>
      
      {walletAddress !== "" ? <p>Connected wallet: {walletAddress}</p> : <p>First connect your wallet.</p>}
        <div style={{paddingTop:"15px", width:"50%", display:"flex", flexDirection:"column", gap:"14px"}}>
          <p>In order for you to be able to use the addressbook of this tracker, you will need to sign up using your 
            email. You also need to verify your wallet address by signing a message with Hana. 
            <br/><br/>This because of 
            future plans to have a verified wallet linked to your email address. At the moment there is no way yet to
            edit the email address linked to your wallet. So make sure you use the correct email address & wallet combination.
            You can do so by clicking the "verify wallet" button.
          </p>
          <p>Signing a message is an offline action, you are not signing an actual blockchain transaction.
            {/* <a href={youtube_url} target="_blank" rel="noopener noreferrer">Click here to watch a video tutorial on private / public key signatures.</a> */}
          </p>

        </div>
    
        <div>
          <div className='address_input_wrapper'
          style={{paddingTop:"25px", paddingBottom:"0px"}}
          >
            <input onChange={handleChangeEmail} placeholder='email' />
            <input placeholder='password' type='password' onChange={handleChangePassword} />
            <input placeholder='retype password' type='password' onChange={handleChangePassword2} />
          </div>
          <div style={{paddingTop:"25px", paddingBottom:"6px"}}>
            <div style={{display:'flex', gap:'6px'}}><p>signature: {shortenString(_signature)}</p>{signatureVerified ? <p><span role="img" aria-labelledby=''>✅</span></p>:<p><span role="img" aria-labelledby=''>⛔️</span></p>}</div>
            <div style={{display:'flex', gap:'6px'}}><p>check message: {shortenString(payload)}</p>{signatureVerified ? <p><span role="img" aria-labelledby=''>✅</span></p>:<p><span role="img" aria-labelledby=''>⛔️</span></p>}</div>
          </div>
          <button 
            onClick={signTransactionFromICONEX}
            disabled={signatureVerified || walletAddress === ""}
            >
              verify wallet
            </button>
          <p style={{marginBottom:"12px", marginTop:"22px", fontSize:"10px"}}>
            Note: By signing up you acknolewdge that you understand that this website/product is in Beta stage. You promise that you will 
            <br/>not complain about things not working perfect. Also, the addressbook might at some point have some features changed or restricted.
          </p>
          <div style={{display:"flex", flexDirection:"row", gap:"10px"}}>
            <button 
            onClick={handleClick}
            disabled={!isEnabled}
            >
              sign up
            </button>

          </div>
          {error && <p>{error}</p>}
        </div>
     
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    walletAddress: state.storage.walletAddress,
  };
};

export default connect(mapStateToProps)(Signup);