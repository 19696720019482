import React, {useState, useContext} from 'react'
import AppContext from '../../AppContext'
import { act } from 'react-test-renderer'

function Login() {
    const { loggedInUser, setLoggedInUser, pb, activeWallet } = useContext(AppContext)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleClick = async () => {
        if (!email || !password) {
            return
        }
        try {
            const authData = await pb.collection('users').authWithPassword(
                email,
                password,
            );
            // console.log(authData)
            setLoggedInUser(authData['record'])
        } catch (e) {
            console.log(e)
        }
    }

    return (
    <div style={{paddingTop:"28px"}}>
        <div>
            <p className='title'>LOG IN</p>
            <div style={{paddingTop:"20px"}}>
                <p style={{paddingBottom:"12px"}}>You have an account set up for the wallet you are connected with.</p>
                <p>Connected wallet: {activeWallet}</p>
            </div>
        </div>
        <div className='address_input_wrapper' style={{marginTop:"12px"}}>
            <input onChange={handleChangeEmail} placeholder='email' />
            <input placeholder='password' type='password' onChange={handleChangePassword} />
          <button onClick={handleClick}>login</button>
          <button>forgot password?</button>
        </div>
    </div>
  )
}

export default Login