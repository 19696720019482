import React, { Component } from 'react';
import { ProposalListPageContainer } from '../containers'

class ProposalListPage extends Component {
  render() {
    return (
      <ProposalListPageContainer />
    );
  }
}

export default ProposalListPage;
