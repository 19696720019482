import React from 'react';
import { Link } from 'react-router-dom';
import useAddressBook from '../../../hooks/useAddressBook';
import { shortenString } from '../../../utils/utils';

const LinkCell = ({ pageType, to, label, aClassName, onClick, onClickTab }) => {
    const {checkAddressBook} = useAddressBook()
    
    function handleOnClick() {
        // This block changes the current tab to the first one (Transaction)
        // tab once the address link is clicked
        if (typeof onClickTab === 'function') {
            onClickTab(0)
        }
        if (typeof onClick === 'function') { 
            onClick()
        }
    }

    // check if the address is in the address book
    const _checkAddressBook = (address) => {
        const returnAdr = checkAddressBook(address)

        if (returnAdr === address) {
           return shortenString(address, 6, 6)
        } else {
            return returnAdr
        }
    }

    return (
        <Link
            className={aClassName}
            to={`/${pageType}/${to}`}
            onClick={handleOnClick}            
            title={to}
        >
            {/* {label || to} */}
            { 
                to !==null && 
                to !== undefined 
                && to.length > 40 ? 
                _checkAddressBook(to) : checkAddressBook(to) } 
        </Link>
    )
}

export default LinkCell;

